.header {
  width: 100%;
  height: 80px;
  background: rgba(17, 17, 17, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
}

.burger__button {
  display: none;
}

.header.light {
  background: rgba(245, 245, 245, 0.6);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.header__wrapper {
  width: 1200px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header__logo-navigation {
  display: flex;
  gap: 70px;
  align-items: center;
  height: 80px;
}

.header__logo {
  width: 180px;
  height: 46px;
  flex-shrink: 0;
}

.header__nav {
  display: flex;
  gap: 40px;
}

.header__link {
  color: var(--white);
}

.header__link.light {
  color: black;
}

.header__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 217px;
  height: 50px;
  border: 1px solid #fff;
  background-color: inherit;
  color: white;
}

.header__button.light {
  border: 1px solid #000;
  color: black;
}

.header__link {
  transition: 0.25s;
}

.header__link:hover {
  color: #cd2222;
}

.header__link-first {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.header__menu {
  position: absolute;
  top: 51px;
  left: -30px;
  background: #060606;
  width: 205px;
  height: 188px;
  flex-shrink: 0;
  transition: 0.25s;
  display: none;
}

.header__menu.light {
  background: rgba(245, 245, 245, 1);
}

.header__menu.active {
  padding: 20px 25px 25px 25px;
  position: absolute;
  top: 51px;
  left: -30px;
  background: #060606;
  width: 225px;
  height: 240px;
  flex-shrink: 0;
  opacity: 1;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__menu.light.active {
  background: rgba(245, 245, 245, 1);
}

.header__menu-link {
  width: 100%;
  height: 50px;
  display: flex;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  align-items: center;
  font-size: 16px;
  transition: 0.25s;
}

.header__menu-link.light {
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.header__menu-link:hover {
  color: #cd2222;
}

.triangle {
  transition: 0.4s;
}

.triangle.active {
  transform: rotate(180deg);
}

.header__buttons {
  display: flex;
  gap: 20px;
  align-items: center;
}

@media (max-width: 1200px) {
  .header {
    width: 100%;
    height: 64px;
  }

  .burger__button {
    display: block;
  }

  .header__wrapper {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .header__logo-navigation {
    display: flex;
    gap: 0px;
    align-items: center;
    height: 64px;
  }

  .header__logo {
    width: 120px;
    height: 30px;
    flex-shrink: 0;
  }

  .header__nav {
    display: none;
  }

  .header__button {
    display: none;
  }

  .header__menu {
    display: none;
  }

  .header__menu.active {
  display: none;
  }

  .toggle__header-button-wrapper {
    display: none;
  }
  
  
}