.burger__menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.5s;
  }
  
  .burger__menu.active {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  
  .burger__overlay {
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    position: relative;
  }

  .burger__overlay.light {
    background: #F5F5F5;
  }
  
  .burger__body {
    position: absolute;
    left: 50%;
    top: 330px;
    transform: translate(-50%, -50%);
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .burger__body-repair {
    display: flex;
    flex-direction: column;
  }

  .burger__body-repair-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .burger__link-repair {
    margin-top: 15px;
    color: white;
    font-size: 16px;
  }

  .burger__link-repair.light {
    color: black;
  }
  
  .burger__link {
    margin-top: 30px;
    color: white;
    text-align: center;
    font-size: 18px;
  }

  .burger__link.light {
    color: black;
  }
  
  .burger__button-menu {
    margin-top: 30px;
    width: 182px;
    height: 45px;
    background-color: inherit;
    border: 1px solid white;
    color: white;
    font-size: 14px;
  }

  .burger__button-menu.light {
    border: 1px solid black;
    color: black;
  }

  .toggle__header-button-wrapper-mobile {
    margin-top: 20px;
  }