.footer {
  width: 100%;
  height: 295px;
  background: #121212;
  display: flex;
  justify-content: center;
}

.footer.light {
  background: #F5F5F5;
}

.footer-wrapper {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.footer__contacts {
  margin-top: 48px;
  width: 263px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.footer__time {
  font-size: 18px;
}

.footer__phones {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer__phone {
  color: white;
}
.footer__phone.light {
  color: black;
}

.footer__mail {
  color: white;
}
.footer__mail.light {
  color: black;
}

.footer__adress {
  text-align: left;
}

.footer__media {
  margin-top: 50px;
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.footer__logo {
  width: 200px;
  height: 55px;
}

.footer__links {
  display: flex;
  gap: 50px;
}

.footer__link,
.footer__link-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.footer__pages {
  width: 260px;
  height: 295px;
  padding: 30px 50px 30px 50px;
  flex-shrink: 0;
  background: #cd2222;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__pages-repair-list {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    width: 150px;
}

.footer__pages-repair-link {
    width: 157px;
    color: white;
    display: flex;
    gap: 5px;
}

.footer__pages-repair-link::before {
    content: "•";
    color: white;
}

.footer__pages-title, .footer__pages-title-link {
    margin-left: 13px;
}

.footer__pages-title {
  color: white;
}

.footer__pages-title-link {
    color: white;
    margin-top: 10px;
}

.footer__adress-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .footer {
    width: 100%;
    height: 225px;
    background: #121212;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }

  .footer-wrapper {
    width: 320px;
    display: flex;
    justify-content: space-between;
  }

  .footer__contacts {
    margin-top: 20px;
    width: 191px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .footer__time {
    font-size: 14px;
  }
  
  .footer__phones {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .footer__phone {
    font-size: 12px;
  }

  .footer__mail {
    font-size: 12px;
  }

  .footer__adress {
    display: none;
  }

  .footer__media {
    margin-top: 20px;
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .footer__logo {
    width: 120px;
    height: 30px;
    flex-shrink: 0;
  }
  
  .footer__links {
    display: flex;
    gap: 10px;
  }
  
  .footer__link,
  .footer__link-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }


.footer__pages {
display: none;
}

.footer__adress-mobile {
  font-size: 12px;
  display: block;
  margin-top: 10px;
  text-align: left;
  width: 320px;
}
  
}