.about__us-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about__us-title {
    margin-top: 130px;
    font-size: 36px;
}

.about__us-description-wrapper {
    margin-top: 50px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.about__us-description {
    text-align: left;
    font-size: 18px;
}

@media (max-width: 1200px) {
    .about__us-title {
        margin-top: 94px;
        font-size: 24px;
    }

    .about__us-description-wrapper {
        margin-top: 20px;
        width: 320px;
        display: flex;
        flex-direction: column;
        gap: 17px;
    }

    .about__us-description {
        text-align: left;
        font-size: 14px;
    }
}