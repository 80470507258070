.contacts__page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts__title {
  font-size: 36px;
  margin-top: 130px;
}

.contacts__page-wrapper {
  margin-top: 50px;
  width: 1200px;
  height: 250px;
  display: flex;
  justify-content: space-between;
}

.contacts__page-phone {
  width: 350px;
  height: 100%;
  border-right: 1px solid rgba(205, 34, 34, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contacts__page-tel {
  margin-top: 14px;
  color: white;
}

.contacts__page-phone-title {
  margin-top: 30px;
}

.contacts__page-tel.light {
  color: black;
}

.contacts__page-tel:nth-child(2) {
  margin-top: 20px;
}

.contacts__page-adress {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid rgba(205, 34, 34, 1);
}

.contacts__page-adress-title {
  margin-top: 45px;
  width: 271px;
}
.contacts__page-adress-adress {
  margin-top: 20px;
  width: 271px;
}

.contacts__page-mail {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts__page-mail-mail {
  margin-top: 20px;
  color: white;
}

.contacts__page-mail-mail.light {
  color: black;
}

.contacts__map {
  margin-top: 100px;
  width: 100%;
  height: 450px;
}

.contact__pjone-icon {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

@media (max-width: 1200px) {
  .contacts__title {
    font-size: 24px;
    margin-top: 94px;
  }

  .contacts__page-wrapper {
    margin-top: 30px;
    width: 320px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }

  .contacts__page-phone {
    width: 220px;
    padding-bottom: 20px;
    height: 100%;
    border-right: none;
    border-bottom: 1px solid #cd2222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contacts__page-tel {
    font-size: 14px;
    margin-top: 10px;
    color: white;
  }

  .contacts__page-phone-title {
    margin-top: 15px;
  }

  .contacts__page-adress {
    width: 220px;
    border-right: none;
    border-bottom: 1px solid #cd2222;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .contacts__page-adress-title {
    margin-top: 15px;
    width: 271px;
    font-size: 14px;
  }

  .contacts__page-adress-adress {
    margin-top: 15px;
    width: 200px;
    font-size: 12px;
  }

  .contacts__page-mail {
    width: 320px;
    padding-top: 20px;
    border-right: none;
  }

  .contact__pjone-icon {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }

  .contacts__page-mail-mail {
    margin-top: 15px;
    color: white;
  }

  .contacts__map {
    margin-top: 30px;
    width: 100%;
    height: 200px;
  }
}
