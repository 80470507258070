.main__about {
  margin-top: 150px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.main__about-image {
  width: 400px;
  height: 350px;
  flex-shrink: 0;
  object-fit: cover;
}

.main__about-texts {
  margin-top: 58px;
  width: 700px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.main__about-title {
  font-size: 36px;
}

.main__about-description  {
    margin-top: 50px;
}

.main__about-title.light, .main__about-description.light {
  color: black;
}

.main__about-more {
    margin-top: 30px;
    align-self: flex-end;
    color: white;
    border-bottom: 1px solid white;
}

.main__about-more.light {
    color: black;
    border-bottom: 1px solid black;
}

@media (max-width: 1200px) {
  .main__about {
    margin-top: 360px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
  
  .main__about-image {
    width: 100%;
    height: 320px;
    flex-shrink: 0;
    object-fit: cover;
  }
  
  .main__about-texts {
    margin-top: 30px;
    width: 320px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .main__about-title {
    font-size: 24px;
  }
  
  .main__about-description  {
      margin-top: 20px;
      font-size: 14px;
  }

  
  .main__about-more {
      margin-top: 30px;
      align-self: flex-end;
      color: white;
      border-bottom: 1px solid white;
      font-size: 12px;
  }

  

}