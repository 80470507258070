/* Montserrat font import */

@font-face {
  font-family: "Montserrat-B";
  src: url("./assets/fonts/Montseratt/Montserrat-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

/* Inter font import */

@font-face {
  font-family: "Inter-L";
  src: url("./assets/fonts/Inter/Inter-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-M";
  src: url("./assets/fonts/Inter/Inter-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-R";
  src: url("./assets/fonts/Inter/Inter-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

/* Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

/* Body Styles */
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  display: block;
}

/* Form Element Styles */
input,
button,
select {
  font: inherit;
}

/* Button Styles */
button {
  cursor: pointer;
}

a {
  text-decoration-line: none;
}

video {
  object-fit: cover;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: normal;
  line-height: normal;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

/* Colors */
:root {
  --white: #fff;
  --white-90: rgba(255, 255, 255, 0.9);
  --white-70: rgba(255, 255, 255, 0.7);
  --white-60: rgba(255, 255, 255, 0.6);
  --red-color: #cd2222;
}

.red-color {
  color: var(--red-color);
}

.text-title-one {
  font-family: "Montserrat-B";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.text-title-two {
  font-family: "Inter-L";
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.body-r {
  font-family: "Inter-R";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.body-m {
  font-family: "Inter-M";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.light-text {
  font-family: "Inter-L";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
