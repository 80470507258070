.about__team {
  margin-top: 50px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.about__team-title {
  font-size: 36px;
}

.about__team-description {
  font-size: 18px;
  text-align: left;
}

.about__team-wrapper {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

.about__team-part {
  width: 350px;
  height: 255px;
  flex-shrink: 0;
  background: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about__team-part.light {
  background: #F5F5F5;
}

.about__team-part-name {
    margin-top: 30px;
}

.about__team-part-job {
    margin-top: 10px;
    font-size: 14px;
}

.about__team-part-description {
    margin-top: 10px;
    width: 310px;
}

@media (max-width: 1200px) {
  .about__team {
    margin-top: 30px;
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .about__team-title {
    font-size: 24px;
  }
  
  .about__team-description {
    font-size: 14px;
    text-align: left;
  }
  
  .about__team-wrapper {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    margin-bottom: 30px;
    gap: 15px;
  }
  
  .about__team-part {
    width: 320px;
    height: 187px;
    flex-shrink: 0;
    background: #111;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about__team-part-name {
      margin-top: 20px;
      font-size: 14px;
  }
  
  .about__team-part-job {
      margin-top: 15px;
      font-size: 12px;
  }
  
  .about__team-part-description {
      margin-top: 10px;
      width: 300px;
      font-size: 12px;
  }
  
}