.service__defects {
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #121212;
}

.service__defects.light {
  background: #f5f5f5;
}

.service__defects-title {
  margin-top: 50px;
  font-size: 36px;
}

.service__defects-wrapper {
  margin-top: 50px;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px 100px;
  margin-bottom: 100px;
}

.defect {
  width: 550px;
  display: flex;
  gap: 20px;
}

.defect__icon {
  width: 130px;
  height: 130px;
  flex-shrink: 0;
}

.defect__texts {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.defect__description,
.defect__title {
  text-align: left;
}

@media (max-width: 1200px) {
  .service__defects {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #121212;
  }
  
  .service__defects-title {
    margin-top: 30px;
    font-size: 24px;
  }
  
  .service__defects-wrapper {
    margin-top: 30px;
    width: 320px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px 0px;
    margin-bottom: 30px;
  }
  
  .defect {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  
  .defect__icon {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
  }
  
  .defect__texts {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .defect__description,
  .defect__title {
    text-align: center;
  }

  .defect__title {
    font-size: 14px;
  }

  .defect__description {
    font-size: 12px;
  }

}