.App {
  text-align: center;
  background: #181818;
  color: white;
}

.App.light {
  background: #fff;
  color: black;
}
