.service__before-after {
  width: 100%;
  padding: 100px 0px;
  background: #121212;
  display: flex;
  justify-content: center;
}

.service__before-after.light {
  background: #f5f5f5;
}

.service__before-after-wrapper {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
}

.before__after-image {
  width: 575px;
  height: 400px;
  flex-shrink: 0;
  object-fit: cover;
}

@media (max-width: 1200px) {
  .service__before-after {
    width: 100%;
    padding: 30px 0px;
    background: #121212;
    display: flex;
    justify-content: center;
  }

  .service__before-after-wrapper {
    width: 320px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }

  .before__after-image {
    width: 320px;
    height: 220px;
    flex-shrink: 0;
  }
}
