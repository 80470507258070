.modal__form {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0;
}

.modal__form.active {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 5;
}

.modal__overlay {
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(3px);
  width: 100%;
  height: 100%;
  position: relative;
}

.modal__form-body {
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: 438px;
  background: #151517;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.modal__form-title {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  position: relative;
  color: white;
}

.close__button {
  position: absolute;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  top: -20px;
  right: -20px;
  cursor: pointer;
}

.modal__form-phone {
  color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal__form-description {
  margin-top: 20px;
}

.modal__form-input {
  margin-top: 20px;
  outline: none;
  width: 240px;
  height: 35px;
  flex-shrink: 0;
  background-color: inherit;
  padding-left: 10px;
  color: white;
  border: 1px solid #fff;
  outline: none;
}

.modal__form-input.error {
  border: 1px solid red;
}

.modal__form-button {
  margin-top: 20px;
  width: 240px;
  height: 52px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border: none;
  border: 1px solid white;
  cursor: pointer;
}

.modal__form-response {
  display: none;
}

.modal__form-response.active {
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: 438px;
  background: #151517;
  z-index: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.response__icon {
  margin-top: 20px;
  width: 150px;
  height: 150px;
  flex-shrink: 0;
}

.response__title {
  margin-top: 30px;
  text-align: center;
  font-size: 26px;
  text-transform: none;
  position: relative;
  width: 280px;
}

.response__description {
  margin-top: 14px;
  font-size: 21px;
  text-transform: none;
  text-align: center;
}

.close__button-response {
  position: absolute;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  top: -220px;
  right: 0px;
  cursor: pointer;
}
