.prices__page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.prices-wrapper {
    margin-top: 180px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    margin-bottom: 100px;
}

.prices-wrapper.closed {
    margin-top: 180px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
}

.price-title {
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}

.triangle-price-phone {
    margin-top: 5px;
    display: flex;
    margin-left: 11px;
    transition: .5s;
}

.triangle-price-phone.active {
    margin-top: 5px;
    display: flex;
    margin-left: 11px;
    transform: rotate(180deg);
}

.price-wrapper {
    width: 1200px;
}

.table-price-wrapper {
    height: 0px;
    overflow: hidden;
}

.table-price-wrapper.opened {
    height: unset; 
    overflow: visible;
}

@media (max-width: 1200px) {
    .prices__page {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 150px;
    }
    
    .prices-wrapper {
        margin-top: 150px;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin-bottom: 30px;
    }

    .prices-wrapper.closed {
        margin-top: 94px;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
    }
    
    .price-title {
        width: 320px;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0px;
        padding-left: 20px;
        cursor: pointer;
    }

    .prices-special {
        display: contents;
    }
    
    .triangle-price-phone {
        margin-top: 5px;
        display: flex;
        margin-left: 11px;
        transition: .5s;
    }
    
    .triangle-price-phone.active {
        margin-top: 5px;
        display: flex;
        margin-left: 11px;
        transform: rotate(180deg);
    }
    
    .price-wrapper {
        width: 320px;
    }
    
    .table-price-wrapper {
        height: 0px;
        overflow: hidden;
    }
    
    .table-price-wrapper.opened {
        height: unset; 
        overflow: visible;
    }
}