.benefite {
  width: 200px;
  height: 238px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #111;
}

.benefite.light {
  background: rgba(245, 245, 245, 1);
}

.benefite__icon {
  margin-top: 20px;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}

.benefite__texts {
  width: 160px;
}

.benefite__title {
  margin-top: 30px;
}

.benefite__description {
  margin-top: 15px;
  text-align: center;
}

.benefite__title.light {
  color: black;
}

.benefite__description.light {
  color: black;
}


@media (max-width: 1200px) {
  .benefite {
    width: 320px;
    height: 90px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #111;
    gap: 30px;
  }

  .benefite__icon {
    margin-top: 0px;
    margin-left: 20px;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
  }

  .benefite__texts {
    width: 200px;
  }
  
  .benefite__title {
    margin-top: 0px;
    text-align: left;
    font-size: 14px;
  }
  
  .benefite__description {
    margin-top: 10px;
    text-align: left;
    font-size: 12px;
  }
  
}