.main__video {
  margin-top: 100px;
  width: 100%;
  height: 500px;
  position: relative;
}

.preview__image {
  object-fit: cover;
  height: 500px;
  width: 100%;
}

.play__icon {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

@media (max-width: 1200px) {
  .main__video {
    margin-top: 30px;
    width: 100%;
    height: 200px;
    position: relative;
  }
  
  .preview__image {
    object-fit: cover;
    height: 200px;
    width: 100%;
  }
  
  .play__icon {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  
}