.service__page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service__page-title {
    margin-top: 130px;
    font-size: 36px;
}

.service__page-description-wrapper {
    margin-top: 50px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.service__page-description {
    text-align: left;
    font-size: 18px;
}

@media (max-width: 1200px) {
.service__page-title {
    margin-top: 94px;
    width: 320px;
    font-size: 24px;
}  

.service__page-description-wrapper {
    margin-top: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.service__page-description {
    text-align: left;
    font-size: 14px;
}
}