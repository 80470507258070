.about__values {
  margin-top: 100px;
  width: 100%;
  background: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__values.light {
  background: #f5f5f5;
}

.about__values-title {
  margin-top: 50px;
  font-size: 36px;
}

.about__values-wrapper {
  margin-top: 50px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.about__value {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.about__value-icon {
  width: 130px;
  height: 130px;
  flex-shrink: 0;
}

.about__value-title {
  margin-top: 30px;
}

.about__value-description {
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 1200px) {
  .about__values {
    margin-top: 30px;
    width: 100%;
    background: #121212;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .about__values-title {
    margin-top: 30px;
    font-size: 24px;
  }
  
  .about__values-wrapper {
    margin-top: 30px;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
  
  .about__value {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .about__value-icon {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
  }
  
  .about__value-title {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .about__value-description {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
  }
}