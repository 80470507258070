.service__prices {
    width: 100%;
    background: #181818;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.service__prices.light {
    background: white;
}

.service__prices-title {
    margin-top: 50px;
    font-size: 36px;
}

.service__prices-wrapper {
    margin-top: 50px;
    width: 1200px;
    display: flex;
    flex-direction: column;
}

.service__prices-texts {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 77px 0px 50px;
    margin-bottom: 20px;
}

.service__prices-prices {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.service__prices-price {
    width: 100%;
    height: 76px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service__prices-price.light {
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
}

.service__prices-price:last-child {
    width: 100%;
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    margin-bottom: 100px;
}

.service__prices-price.light:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
}

.service__prices-price-title {
    margin-left: 50px;
}

.service__prices-price-price {
    width: 105px;
    margin-right: 50px;
    display: flex;
    text-align: left;
}

@media (max-width: 1200px) {
  
    .service__prices-title {
        margin-top: 30px;
        font-size: 24px;
    }
    
    .service__prices-wrapper {
        margin-top: 30px;
        width: 320px;
        display: flex;
        flex-direction: column;
    }
    
    .service__prices-texts {
        width: 320px;
        display: flex;
        justify-content: space-between;
        padding: 0px 17px 0px 0px;
        margin-bottom: 20px;
    }

    .service__prices-text {
        font-size: 14px;
    }

    .service__prices-text:first-child {
        width: 200px;
        text-align: left;
    }
    
    .service__prices-prices {
        width: 320px;
        display: flex;
        flex-direction: column;
    }
    
    .service__prices-price {
        width: 100%;
        height: unset;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    
    .service__prices-price:last-child {
        width: 100%;
        height: unset;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
        margin-bottom: 30px;
    }
    
    .service__prices-price-title {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 0px;
        font-size: 12px;
        width: 200px;
        text-align: left;
    }
    
    .service__prices-price-price {
        width: 77px;
        margin-right: 0px;
        display: flex;
        text-align: left;
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}