.main__pluses {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #121212;
}

.main__pluses.light {
  background: #f5f5f5;
}

.main__pluses-wrapper {
  margin-top: 50px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.main__plus {
  width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__plus-icon {
  width: 130px;
  height: 130px;
  flex-shrink: 0;
}

.main__plus-title {
  margin-top: 30px;
}

.main__plus-description {
  margin-top: 15px;
  text-align: center;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .main__pluses {
    width: 100%;
    display: flex;
    justify-content: unset;
    flex-direction: column;
    align-items: center;
    background: #121212;
  }
  
  .main__pluses-wrapper {
    margin-top: 30px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }
  
  .main__plus {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .main__plus-icon {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
  }
  
  .main__plus-title {
    font-size: 14px;
    margin-top: 20px;
  }
  
  .main__plus-description {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 30px;
  }
  
}