.main__repair {
  margin-top: 100px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.main__repair-link {
  width: 250px;
  height: 294px;
  flex-shrink: 0;
  background: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__repair-link.light {
  background: #F5F5F5;
}

.main__repair-icon {
  width: 130px;
  height: 130px;
  flex-shrink: 0;
  margin-top: 30px;
}

.main__repair-texts {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.main__repair-title {
    color: white;
}

.main__repair-title.light {
    color: black;
}

.main__repair-more {
  width: 82px;
    font-size: 14px;
    border-bottom: 1px solid var(--red-color);
}

@media (max-width: 1200px) {
  .main__repair {
    margin-top: 30px;
    width: 320px;
    display: flex;
    justify-content: unset;
    flex-wrap: wrap;
    gap: 19px 20px;
  }
  
  .main__repair-link {
    width: 150px;
    height: 199px;
    flex-shrink: 0;
  }

  
  .main__repair-icon {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    margin-top: 20px;
  }

  .main__repair-texts {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: unset;
    height: 59px;
  }
  
  
  .main__repair-title {
    width: 110px;
    font-size: 14px;
      color: white;
  }
  
  .main__repair-more {
    width: 70px;
    font-size: 12px;
      border-bottom: 1px solid var(--red-color);
  }

}