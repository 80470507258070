.theme__button {
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.theme__button-wrapper {
  display: flex;
  background: #060606;
  border-radius: 20px;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.theme__button-wrapper.checked {
  background: inherit;
  border: 1px solid black;
}

.theme__button-thumbler {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    left: 5px;
    top: 5px;
    background-color: white;
    transition: .5s;
}

.theme__button-thumbler.checked {
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    top: 5px;
    left: unset;
    transform: translateX(54px);
    background-color: black;
}

.theme__icon-wrapper {
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 1;
}