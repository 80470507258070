.main__cover {
  width: 100%;
  height: 750px;
  display: flex;
  justify-content: center;
  position: relative;
}

.main__wrapper-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main__cover-content {
  top: 280px;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 2;
}

.main__cover-title.light {
  color: black;
}

.main__cover-description {
  margin-top: 30px;
}

.main__cover-description.light {
  color: black;
}

.main__cover-button {
  margin-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 217px;
  height: 50px;
  border: 1px solid #fff;
  background-color: inherit;
  color: white;
}

.main__cover-button.light {
  border: 1px solid black;
  color: black;
}

.main__cover-benefits {
    display: flex;
    gap: 50px;
    position: absolute;
    z-index: 2;
    bottom: -307px;
    right: 0;
}

.main__cover-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    position: relative;
}

@media (max-width: 1200px) {
  .main__cover {
    height: 340px;
  }

  .main__cover-content {
    top: 124px;
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    z-index: 2;
  }

  .main__cover-title {
    font-size: 24px;
  }

  .main__cover-description {
    margin-top: 15px;
    font-size: 18px;
    width: 213px;
    text-align: left;
  }

  .main__cover-button {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 134px;
    height: 33px;
    border: 1px solid #fff;
    background-color: inherit;
    color: white;
    font-size: 12px;
  }
  .main__cover-benefits {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    z-index: 2;
    bottom: unset;
    top: 246px;
    right: 0;
}

.main__cover-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
}
}